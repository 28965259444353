<template>
<div class="app-container">
  <!-- banner -->
  <div class="banner">
    <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
    <div class="left">
      <h2>Job</h2>
      <div class="intro-text">
        You are here:  HomePages / Job
      </div>
    </div>
  </div>
  <!-- news-list -->
  <div class="job columns inner">
    <div class="center-header" v-if="pageData.contentManagement">
      <h3>{{pageData.contentManagement.title}}</h3>
      <span>{{pageData.contentManagement.description}}</span>
    </div>
    <div class="job-infomation">
      <div>{{$t('common.jobInfo')}}</div>
      <div class="right-button dis-flex">
        <el-button class="job-btn" @click="goDelivery">{{$t('common.delivery')}}</el-button>
      </div>
    </div>
    <div class="job-box">
      <!-- 筛选 -->
      <div class="filter-box">
        <div class="filter-top">
          <div class="head">{{$t('common.filterfields')}}</div>
          <el-form class="filter" ref="elForm" :model="searchData" size="small" label-suffix="：">
            <el-form-item :label="$t('common.Workplace')" prop="addressName" >
                <el-radio-group v-model="searchData.addressName" size="medium" @input="handleSearch">
                  <el-radio-button :label="''">{{ $t('common.all') }}</el-radio-button>
                  <el-radio-button :label="item.name" v-for="item in placeList" :key="item.id"></el-radio-button>
                </el-radio-group>
            </el-form-item>
             <!--<el-form-item label="Category" prop="chapterName">
                <el-radio-group v-model="searchData.radio4" size="medium">
                  <el-radio-button label="上海"></el-radio-button>
                  <el-radio-button label="北京"></el-radio-button>
                  <el-radio-button label="广州"></el-radio-button>
                  <el-radio-button label="深圳"></el-radio-button>
                </el-radio-group>
            </el-form-item>-->
          </el-form> 
        </div>
        <div class="filter-bottom dis-flex">
          <div class="search">
            <el-input :placeholder="$t('common.pleaseEnter')" v-model="searchData.name" clearable>
              <el-button slot="append" clearable @click="handleSearch">{{$t('common.search')}}</el-button>
            </el-input>
          </div>
        </div>
      </div>
      <!-- 招聘列表 -->
      <div class="job-list-box">
        <div class="job-list">
          <ul v-if="pageList.length!==0" class="dis-flex">
            <li class="pos-rl" v-for="item in pageList" :key="item.id" @click="goToDetail(item,1)">
              <div class="title">
                <h4 class="dis-inbl" v-html="item.name"></h4>
                <div class="title-info">
                  <span><svg-icon icon-class="location" :size="14"/>{{item.addressName}}</span>
                  <!-- <span><svg-icon icon-class="building" :size="14"/>Government and enterprise industry solutions and services</span>
                  <span><i class="el-icon-user-solid"></i>1</span>
                  <span><svg-icon icon-class="time" :size="14"/>15-12-2022</span> -->
                </div>
              </div>
              <div class="detail p16-txt" v-html="item.jobDescription">
              </div>
              <!-- <div class="right-button dis-flex">
                <el-button class="deliver-btn blue-btn" @click="goToDetail(item,1)">{{$t('common.delivery')}}</el-button>
                <el-button class="deliver-btn " @click="goToDetail(item,2)">{{$t('common.referdelivery')}}</el-button>
              </div> -->
            </li>
          </ul>
          <div v-if="detailDialog">
            <el-dialog title="提示" :visible.sync="detailDialog" width="40%" center :before-close="handleClose" custom-class="delivery-dialog">
              <Input :rowData="rowData" @cancel="handleClose"></Input>
            </el-dialog>
          </div>
        </div>
        <div class="pagination dis-flex">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageConfig.currentPage"
            :page-size="pageConfig.pageSize"
            layout="prev, pager, next"
            :total="pageConfig.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import request from "@/utils/request.js";
import Input from "./Recruit-input.vue";

export default {
  name: 'Home',
  components: { Input },
  data() {
    return {
      pageConfig: {
        pageSize: 9,
        total: 0,
        currentPage: 1,
        pageSizes: [
            10, 50, 100, 150, 200]
      },
      searchData:{},
      rowData:{},
      parentData:{},
      pageData:{},
      pageList:[],
      placeList:[],
      bannerData:{},
      detailDialog:false,
    }
  },
  methods: {
    handleCurrentChange(val) {
        this.pageConfig.currentPage = val;
        this.getList()
    },
    handleSearch() {
        this.pageConfig.currentPage = 1;
        this.getList()
    },
    getList(){
      post("/joinUs/getPage",{
        page:this.pageConfig.currentPage,
        limit:this.pageConfig.pageSize,
        name:this.searchData.name,
        addressName:this.searchData.addressName
      }).then(res => {
          if (res.code == 1) {
              this.pageList=res.data.data
              this.pageConfig.total=res.data.count
          } else {
              this.$message.error(res.data);
          }
      });
    },
    // 工作地点
    getAddressList(){
      post("/address/getList",).then(res => {
          if (res.code == 1) {
              this.placeList=res.data
          } else {s
              this.$message.error(res.data);
          }
      });
    },
    getData(){
      if(this.parentData.id){
        post("/publicController/publicData",{id:this.parentData.id}).then(res => {
            if (res.code == 1) {
                this.pageData=res.data.business[0]
                this.bannerData=res.data.homeIntroduction[0]
            } else {
                this.$message.error(res.data);
            }
        });
      }
    },
    getBgStyle(item){
      if (item.picture) {
        return {
        backgroundImage:
          'url(' + item.picture + ') ',
        }
      } else {
        return {
        backgroundImage:
          'url(' +
          require('../../../static/news-banner.jpg') +
          ') ',
        }
      }
    },
    getImage(row) {
      setTimeout(function () {
        if (!row.pictureUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.picture
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.pictureUrl =  linkNode.href 
          })
        }
      }.bind(this), 1000);
    },
    // 工作详情
    goToDetail(data,type){
      this.$router.push({ path:'/recruit-detail',query:{id:data.id}})
    },
    // 直接投递窗口
    goDelivery(data,type){
        this.detailDialog = true
    },
    handleClose(data,type){
        this.detailDialog = false
    },
  },
  mounted() {
    this.parentData = this.$route.query
    console.log(this.parentData);
    this.getList()
    this.getData()
    this.getAddressList()

    // this.navIndex = this.$route.path
    // if (Cookies.get('ClinLan') == 'en') {
    //   this.language = 'English'
    // } else {
    //   this.language = '中文'
    // }
  },
}
</script>
<style lang="scss" scoped>
.inner{
  width: 1620px;
  margin:0 auto;
}
.columns{
  padding:100px 0
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}
.right-button{
  justify-content: end;
  margin-top: 20px;
  .deliver-btn{
    color:#fc7700;
    background-color: #FFF5EC;
    border-color: #FFF5EC;
    border-radius: 25px;
    padding:15px 30px;
    // margin:20px 0;
    height: 45px;
  }
  .blue-btn{
    background-color: #f0f9ff;
    border-color: #f0f9ff;
    color: #2ca9f9;
  }
}
// banner
.banner{
  background-color: #F2F7FD;
  background-image: url('../../../static/news-banner.jpg');
  background-size:cover;
  width: 100%;
  position: relative;
  height: 410px;
  overflow: hidden;
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:110px;
  color:#fff;

  h2{
    font-weight: 800;
    font-size: 76px;
  }
  .intro-text{
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
}
}

.job{
  padding:100px 0 100px;
  // margin: 0 100px;
  // .job-box{
  //   margin-top: 100px;
  // }
  .job-infomation{
    margin-top: 50px;
    padding:30px;
    font-weight: 300;
    background-color: #F4F4F5;
    border-radius: 20px;
    line-height: 1.5;
    .job-btn{
      color:#fff;
      background-color: #0D63DC;
      border-color: #0D63DC;
      border-radius: 25px;
      padding:17px 45px;
      // margin:20px 0;
    }
  }
  .filter-box{
    margin:50px 0 50px;
    // width:100%;
    min-height: 180px;
    // height: 260px;
    box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    .head{
      color:#0e63dc;
      font-weight: bold;
    }
    .filter{
      margin:20px 0 ;
    }
    .filter-bottom{
      justify-content: end;
      .search{
        width:700px
      }
    }
  }
  .job-list-box{
    flex:1;
    // padding: 0 50px;
    width: 100%;
    .job-list{
      box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
      border-radius: 20px;
    }
    .job-list ul{
      flex-wrap: wrap;
    }
    li{
      padding:30px;
      width:100%;
      // box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
      cursor: pointer;
      max-height: 13em;
      overflow: hidden;
      border-bottom: 1px dashed #D4D7D9;
      .title{
        h4{
          line-height: 2em;
          margin-bottom: 25px;
          min-height: 2em;
          overflow : hidden;
          display: -webkit-box;    /*弹性伸缩盒子*/
          -webkit-box-orient: vertical; /*子元素垂直排列*/
          -webkit-line-clamp: 2;   /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis; 
        }
        .title-info{
          margin-bottom: 30px;
          .svg-icon{
            color:#666666;
            padding-right: 5px;
          }
        }
        span{
          font-size: 16px;
          color:#5c5c5c;
          margin-right: 20px;
        }
      }
      .detail{
        font-size: 16px;
        line-height: 1.5em;
        width: 100%;
        height: 7.5em;
        color:#333;
        overflow : hidden;
        // overflow-x: hidden;
        // display: -webkit-box;    /*弹性伸缩盒子*/
        // -webkit-box-orient: vertical; /*子元素垂直排列*/
        // -webkit-line-clamp: 3;   /*可以显示的行数，超出部分用...表示*/
        // text-overflow: ellipsis; 
      }
      &:hover{
        background-color: #F0F5F9;
      }
    }
    li:last-child{
      border-bottom:none;
      border-radius:0 0 20px 20px
    }
    li:first-child{
      border-radius: 20px 20px 0 0;
    }
  }
}
.pagination{
  margin:80px auto 100px;
  justify-content: center;
}
::v-deep .el-pagination.is-background {
  .btn-next, .btn-prev, .el-pager li{
    background-color: #3960D9;
    color:#fff;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  .el-pager li:not(.disabled).active{
    background-color: #262626;
  }
}
::v-deep .el-form{
  .el-radio-button .el-radio-button__inner{
    border:none
  }
  .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background-color: #0D63DC;
    border-radius: 20px;
  }
}
::v-deep .el-input{
  font-size: 16px;
  .el-input__inner{
    border:#0D63DC 2px solid;
    height: 55px;
    border-radius: 20px 0 0 20px;
    color:#0D63DC;
  }
  .el-input-group__append{
    border:#0D63DC 2px solid;
    background-color:#0D63DC ;
    color:#fff;
    padding:0 40px;
    border-radius: 0 20px 20px 0;
  }
}

</style>
