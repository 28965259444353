<template>
<div class="app-container">
  <!-- job-form -->
  <div class="apply-box dis-flex">
    <div class="left">
      <!-- <div class="head "><h4>{{$t('common.submitApply')}}</h4></div> -->
      <el-form ref="elForm" :inline="true" :rules="rules" :model="formData" size="large" class="submit">
        <el-form-item label="" prop="name" style="width:48%" class="require">
            <el-input v-model="formData.name" :placeholder="$t('common.name')" clearable >
            </el-input>
        </el-form-item>
        <el-form-item label="" prop="mailbox" style="width:48%" class="require">
            <el-input v-model="formData.mailbox" :placeholder="$t('common.email')" clearable >
            </el-input>
        </el-form-item>
        <el-form-item label="" prop="phoneNumber" style="width:48%" class="require">
            <el-input v-model="formData.phoneNumber" :placeholder="$t('common.phoneNumber')" clearable >
            </el-input>
        </el-form-item>
        <el-form-item label="" prop="remark" style="width:48%">
            <el-input v-model="formData.remark" :placeholder="$t('common.resumeUpload')" clearable >
            </el-input>
            <el-upload
            class="upload-file"
            :action="baseURL+'file/upload'"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :limit="1"
            :file-list="fileList">
            <el-button size="small" type="primary">{{$t('common.clickUpload')}}</el-button>
          </el-upload>
        </el-form-item>
        <!-- <div class="refer ft-slim">
         <span @click="addRefer"><i class="el-icon-plus"></i>{{$t('common.ifReference')}}</span>
        </div>
        <div v-if="haveRefer">
          <el-form-item label="" prop="presenterName" style="width:48%" class="require">
              <el-input v-model="formData.presenterName" :placeholder="$t('common.hisName')" clearable >
              </el-input>
          </el-form-item>
          <el-form-item label="" prop="presenterMailbox" style="width:48%" class="require">
              <el-input v-model="formData.presenterMailbox" :placeholder="$t('common.hisEmail')" clearable >
              </el-input>
          </el-form-item>
          <el-form-item label="" prop="presenterNumber" style="width:48%" class="require upload" >
              <el-input v-model="formData.presenterNumber" :placeholder="$t('common.hisPhoneNum')" clearable >
              </el-input>
          </el-form-item>
        </div> -->
      </el-form>
      <div class="form-btn">
          <el-button class="read-btn" @click="submitForm()" :disabled="clickDone">{{$t('common.applyPosition')}}</el-button>
          <!-- <el-button class="return-btn" type="info" plain @click="goback()">{{$t('common.backList')}}</el-button> -->
      </div>
    </div>
    <!-- <div class="right">
      <div class="quali" v-if="rowData">
        <h4 class="pos-rl">{{rowData.name}}</h4>
        <div class="text ft-slim p16-txt" v-html="rowData.jobDescription">
        </div>
      </div>
    </div> -->
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import baseURL from '@/api/baseURL.js'


export default {
  name: 'Home',
  props:{
    rowData:{
        type: Object,
        default() {
            return {};
        }
    },
  },
  data() {
    return {
      rules: {
          name: [{
              required: true,
              message: this.$t('common.pleaseEnter')+this.$t('common.name')+'!',
              trigger: 'blur'
          }], mailbox: [{
              required: true,
              message: this.$t('common.pleaseEnter')+this.$t('common.email')+'!',
              trigger: 'blur'
          }],phoneNumber: [{
              required: true,
              message: this.$t('common.pleaseEnter')+this.$t('common.phoneNumber')+'!',
              trigger: 'blur'
          }],presenterName: [{
              required: true,
              message: this.$t('common.pleaseEnter')+this.$t('common.hisName')+'!',
              trigger: 'blur'
          }],presenterNumber: [{
              required: true,
              message: this.$t('common.pleaseEnter')+this.$t('common.hisPhoneNum')+'!',
              trigger: 'blur'
          }],presenterMailbox: [{
              required: true,
              message: this.$t('common.pleaseEnter')+this.$t('common.hisEmail')+'!',
              trigger: 'blur'
          }]
      },
      parentData:{},
      formData:{},
      // haveRefer:false,
      fileList:[],
      baseURL:baseURL,
      clickDone:false,
    }
  },
  methods: {
    // addRefer(){
    //   this.haveRefer=!this.haveRefer
    // },
    goback(){
      this.$emit('cancel')
    },
    submitForm(){
      console.log(this.formData);
      this.$refs['elForm'].validate(valid => {
          if (!valid) {
              this.$message.error(this.$t('common.pleaseComplete'));
              return false
          }else {
              this.formData.applyType=1
              this.formData.positionName=this.rowData.name
              post("talent/add",{
                    ...this.formData
                }).then(res => {
                    if (res.code == 1) {
                        this.clickDone = true
                        this.$message.success(this.$t('common.submitSuccess'));
                    } else {
                        this.$message.error(res.data);
                    }
              });
          }
      })
    },
    // 上传成功
    handleSuccess(res){
      this.formData.fileId= res.data.id
    },
    handleExceed(files, fileList){
    }
  },
  mounted() {
    this.parentData = this.$route.query
  },
}
</script>
<style lang="scss" scoped>
.inner{
  width: 1620px;
  margin:0 auto;
}
.columns{
  padding:100px 0
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}
.form-btn{
  margin-top: 30px;
  .read-btn{
    color:#fc7700;
    background-color: #FFF5EC;
    border-color: #FFF5EC;
    border-radius: 25px;
    padding:17px 45px;
    // margin:20px 0;
    height: 50px;
  }
  .read-btn:hover{
    color:#ffffff;
    background-color: #fc7700;
    border-color: #fc7700;
    border-radius: 25px;
    padding:17px 45px;
    height: 50px;
  }
  .return-btn{
    border-radius: 25px;
    border-color: #F4F4F5;
    padding:17px 45px;
    height: 50px;
  }
}
// banner
.banner{
  background-color: #F2F7FD;
  // background-image: url('../../static/news-banner.jpg');
  background-size:cover;
  width: 100%;
  position: relative;
  height: 410px;
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:110px;
  color:#fff;

  h2{
    font-weight: 800;
    font-size: 76px;
  }
  .intro-text{
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
}
}

.apply-box{
  justify-content: space-between;
  padding:20px;
  .left{
    // width:50%;
  }
  .head{
    padding: 0 20px 20px;
    font-weight:bold ;
    // line-height: 40px;
  }
  .refer{
    font-size: 16px;
    padding:20px 10px;
    cursor: pointer;
    i{
      font-size: 14px;
      margin-right: 5px;
    }
    span{
      text-decoration: underline;
    }
  }
  .right{
    .quali{
      box-sizing: border-box;
      background-color: #F5FBFF;
      border-radius: 20px;
      width:680px;
      min-height:200px ;
      margin-right:35px ;
      padding:30px;
      h4{
        margin-bottom: 15px;
        padding-left: 20px;
      }
      h4::before{
        display: block;
        content: '';
        width: 13px;
        height: 13px;
        border-radius: 20px;
        background-color: #0D63DC;
        position: absolute;
        top:7px;
        left: 0px;
      }
      .text{
        font-size: 16px;
        line-height: 2.2;
        padding-left: 20px;
      }
    }
  }
}

// .pagination{
//   margin:80px auto 100px;
//   justify-content: center;
// }
// >>> .el-pagination.is-background {
//   .btn-next, .btn-prev, .el-pager li{
//     background-color: #3960D9;
//     color:#fff;
//     border-radius: 100%;
//     height: 50px;
//     width: 50px;
//     line-height: 50px;
//   }
//   .el-pager li:not(.disabled).active{
//     background-color: #262626;
//   }
// }
::v-deep .el-form{
  .el-radio-button .el-radio-button__inner{
    border:none
  }
  .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background-color: #0D63DC;
    border-radius: 20px;
  }
  .el-form-item{
    position: relative;
  }
  .el-form-item__content{
    width: 100%;
  }
  .upload{
    position: relative;
  }
  .upload-file{
    position: absolute;
    top: 9px;
    left: 20px;
    // opacity: 0;
  }
  .el-button{
    width: 300px;
    opacity: 0;
  }
  .el-upload-list{
    margin-top: 20px;
  }
}
::v-deep .submit .el-input{
  font-size: 16px;
  
  .el-input__inner{
    border:rgb( 229, 229, 229 ) 1px solid;
    height: 50px;
    border-radius: 20px;
    padding-left: 30px;
  }
}
::v-deep .require .el-input::before{
    content: '*';
    color:red;
    font-size: 16px;
    display: inline;
    position: absolute;
    top:9px;
    left:20px
  }

</style>
